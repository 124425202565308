<template>
    <div>
        <strong>User Photo Upload</strong>
        <br>
        <div class="form-group">
            <label>User</label>
            <Typeahead ref="userFileHandlerUser" cdi_type="user" v-bind:ID.sync="state.referenceID" />
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "User",
        components: {
            Typeahead
        },
        data() {
            return {
                state: fileUploadStore.state
            }
        },
        created() {
            fileUploadStore.initialize();
        },
        mounted() {
            if (this.$appStore.state.fileUploadContextData.data.first_name && this.$appStore.state.fileUploadContextData.data.last_name) {
                this.$refs.userFileHandlerUser.$data.object = `${this.$appStore.state.fileUploadContextData.data.first_name} ${this.$appStore.state.fileUploadContextData.data.last_name}`;
            }
        }
    }
</script>

<style scoped>

</style>